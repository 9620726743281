import { requests } from "@/utils/requests";

export const getCaseLists = async () => {
  const data = await requests({
    url: `/api/admin/case/grid`,
    method: "GET",
  });
  if (data.status == 200) return data.data;
  else return data;
};

export const deleteCase = async (case_uuid) => {
  const data = await requests({
    url: `/api/admin/case?case_uuid=${case_uuid}`,
    method: "DELETE",
  });
  if (data.status == 200) return data.data;
  else return data;
};

export default {
  getCaseLists,
  deleteCase,
};
